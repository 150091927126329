import React, { useState, useContext, useEffect } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import {
  Background,
  Button,
  TextButton,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  Row,
  Column,
  AuthContext,
  Modal,
  ModalInner,
  ComponentScroll,
  Text,
} from '@stokr/components-library'
import { FormError } from 'components/Form/Form'
import backgroundImage from 'static/images/covers/rocket.png'
import sumsubLogo from 'static/images/sumsub_logo.jpg'
import redirectUrl from 'utils/redirect-url'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import ToDoTask, { ToDoTaskState } from 'components/ToDoList/ToDoListTask'
import { iconsMap } from 'components/Icon/Icon.style'
import { DisplayOnBreakpoint } from 'styles/rwd'
import SumsubWebSdk from '@sumsub/websdk-react'
import fetchData from 'api/fetchData'
import { loaderGif } from '@stokr/components-library/dist/components/StokrLoader/StokrLoader'
import { useNavigate } from 'react-router'
import { UserTypes } from 'constants/enums'

const Telindus = styled.img`
  top: 14px;
  position: relative;
  width: 80px;
  left: 8px;

  @media screen and (max-width: 398px) {
    width: 70px;
    top: 10px;
  }
`
export const RedirectSpacer = styled.div`
  height: 14px;
`

const VerifyIdentity = ({ setShowPending }) => {
  const { user, updateUser, logoutUser } = useContext(AuthContext)
  const { _id, kyc_status, user_type } = user
  const [showSumSubPopup, setshowSumSubPopup] = useState(false)
  const [error, setError] = useState('')
  const [accessToken, setaccessToken] = useState(null)
  const navigate = useNavigate()

  const showVerifyIndentityButton =
    !kyc_status || kyc_status === 'Draft' || kyc_status === 'Reapply'

  const cancelledKYC = kyc_status === 'Cancelled'

  const fetchSumSubAccessToken = async (returnValue = false) => {
    const levelName =
      user_type === UserTypes.investor_entity ? 'STOKR KYB' : 'basic-kyc-level'
    try {
      const response = await fetchData('sumsub/get-access-token', {
        userId: user._id,
        ttlInSecs: 1800, //30 minutes
        levelName,
      })

      if (!returnValue && response.token) {
        setaccessToken(response.token)
      }
      console.log('🚀 ~ fetchSumSubAccessToken ~ response:', response)
      return response.token
    } catch (error) {
      console.log('🚀 ~ fetchSumSubAccessToken ~ error:', error)
      if (error.response?.status === 401) {
        console.log('LOGOUTTT')
        logoutUser()
      }
    }
  }

  const onComplete = async () => {
    error && setError('')

    try {
      await updateUser({
        kyc_status: 'In Progress',
      })

      setShowPending(true)
    } catch (error) {
      setshowSumSubPopup(false)
      setError('Something went wrong. Please try again.')
    }

    document.body.style.overflowY = 'unset'
  }

  const handleExpiredToken = async () => {
    console.log('Handling expired token!!')

    try {
      const token = await fetchSumSubAccessToken(true)
      console.log('🚀 ~ handleExpiredToken ~ token:', token)
      return token
    } catch (error) {
      console.log('🚀 ~ handleExpiredToken ~ error:', error)
      throw error
    }
  }
  const handleOpenPopup = async () => {
    setshowSumSubPopup(!showSumSubPopup)
    if (!accessToken) {
      await fetchSumSubAccessToken()
    }
  }

  const handleOnError = async (error) => {
    console.log('🚀 ~ handleOnError ~ error:', error)

    if (error?.error === 'Not authorized') {
      console.log('fetching token again: ')
      setaccessToken(null)
      await fetchSumSubAccessToken()
    }
  }

  const handleOnMessage = (message) => {
    console.log('🚀 ~ handleOnMessage ~ message:', message)
    if (message === 'idCheck.onApplicantSubmitted') {
      onComplete()
    }
  }

  const onClose = async () => {
    setshowSumSubPopup(false)

    // Prevent 'Draft' from overwriting another status, e.g. 'Pending'
    if (!kyc_status) {
      try {
        await updateUser({
          kyc_status: 'Draft',
        })
      } catch (error) {}
    }

    document.body.style.overflowY = 'unset'
  }

  return (
    <>
      <Row main>
        <Column part={8} mainFixed>
          <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
            <Background src={backgroundImage} mobileRatio="16:9" />
          </DisplayOnBreakpoint>
        </Column>

        <Column part={8} mainRelative>
          {redirectUrl ? (
            <RedirectSpacer />
          ) : (
            <ComponentWrapper noPaddingVertical>
              <TextButton onClick={() => navigate('/country-of-residence')}>
                Back to Country of{' '}
                {user_type === UserTypes.investor_entity
                  ? 'registration'
                  : 'residence'}
              </TextButton>
            </ComponentWrapper>
          )}

          <ComponentWrapper>
            {user.countryObj?.signUpWarning ? (
              <Text>
                <h1>
                  INVESTING IN PUBLIC ROUNDS IS NOT AVAILABLE FROM YOUR
                  RESIDENCE YET.
                </h1>
                <p>
                  To participate in private rounds, please continue signing up
                  so we can fully verify you as an investor. However, to
                  participate in private rounds you require a special invite
                  link which is sent to eligible investors only.
                </p>
                <p>
                  Powered by
                  <Telindus src={sumsubLogo} />
                </p>
              </Text>
            ) : (
              <Text>
                <h1>
                  {user_type === UserTypes.investor_entity
                    ? 'VERIFY YOUR ENTITY'
                    : 'VERIFY YOUR IDENTITY'}
                </h1>
                <p>
                  {user_type === UserTypes.investor_entity
                    ? 'To start the process please click below.'
                    : 'Before you can invest please verify your identity with your passport or ID card.'}
                </p>
                <p>
                  Powered by
                  <Telindus src={sumsubLogo} />
                </p>
              </Text>
            )}
          </ComponentWrapper>

          {user_type === 'investor_entity' && (
            <ComponentWrapper noPaddingTop>
              <Text>
                <h5 style={{ fontWeight: 700, paddingBottom: 32 }}>
                  Prepare your documents before you start
                </h5>
              </Text>

              <FAQ
                noScroll
                items={[
                  {
                    title: 'Incorporation documents',
                    content: (
                      <Text small>
                        <ul>
                          <li>
                            Articles of Association/Memorandum of
                            Incorporation/Trust Deed.
                          </li>
                          <li>Certificate of Incorporation if applicable.</li>
                        </ul>
                      </Text>
                    ),
                  },
                  {
                    title: 'Company details',
                    content: (
                      <Text small>
                        <ul>
                          <li>
                            Recent extract from a company/business trade
                            registry (not older than 3 months). If your country
                            of registration does not provide such extract,
                            please note that an equivalent of a company/business
                            trade registry is a Certificate of Incumbency and
                            Certificate of Good Standing from the country of
                            registration.
                          </li>
                        </ul>
                      </Text>
                    ),
                  },
                  {
                    title: 'Control structure',
                    content: (
                      <Text small>
                        <ul>
                          <li>
                            Recent and signed register of directors or
                            equivalent.
                          </li>
                        </ul>
                      </Text>
                    ),
                  },
                  {
                    title: 'Ownership structure',
                    content: (
                      <Text small>
                        <ul>
                          <li>
                            Recent and signed shareholder register/register of
                            members.
                          </li>
                        </ul>
                      </Text>
                    ),
                  },
                ]}
              />

              <Text small style={{ marginTop: '20px' }}>
                <p>
                  <strong>Important Note:</strong> You need to provide the
                  ID/Passport of each individual qualifying as a Director/UBO in
                  the company as well as a proof of address (e.g. utility bill)
                  for each UBO.
                </p>
                <p>
                  If there is another entity that holds 25% or more in the
                  company, you would need to provide the same type of documents
                  for this entity. Please follow the process from the
                  verification link.
                </p>
              </Text>
            </ComponentWrapper>
          )}

          {/* this logic should be moved to pending page */}
          {showVerifyIndentityButton ? (
            <>
              {/* SUMSUB button popup */}
              <ComponentWrapper noPaddingTop>
                <Button onClick={handleOpenPopup}>
                  {user_type === UserTypes.investor_entity
                    ? 'Verify Entity'
                    : 'Verify Identity'}
                </Button>

                <FormError withTopSpacing show={error}>
                  {error}
                </FormError>
              </ComponentWrapper>

              <ComponentWrapper noPaddingTop>
                <TextButton
                  onClick={() => {
                    if (redirectUrl) {
                      deleteRedirectCookieAndNavigate()
                    } else {
                      navigate('/taxid')
                    }
                  }}
                >
                  I'll do this later
                </TextButton>
              </ComponentWrapper>
            </>
          ) : (
            <>
              <ComponentWrapper noPaddingTop>
                <ToDoTask
                  title={
                    user_type === UserTypes.investor_entity
                      ? 'Verify Entity'
                      : 'Verify Identity'
                  }
                  message={
                    cancelledKYC ? (
                      <>
                        Please contact{' '}
                        <a
                          href="mailto:support@stokr.io"
                          style={{ textDecoration: 'underline' }}
                        >
                          support@stokr.io
                        </a>
                      </>
                    ) : (
                      'Our anti-fraud squad is still working'
                    )
                  }
                  state={ToDoTaskState.IN_PROGRESS}
                  icon={cancelledKYC ? iconsMap.warning : iconsMap.inProgress}
                />
              </ComponentWrapper>
              <ComponentWrapper noPaddingTop>
                <Button
                  onClick={() => {
                    if (redirectUrl) {
                      deleteRedirectCookieAndNavigate()
                    } else {
                      navigate('/taxid')
                    }
                  }}
                >
                  Continue
                </Button>
              </ComponentWrapper>
            </>
          )}

          <Modal
            isOpen={showSumSubPopup}
            onClose={() => setshowSumSubPopup(false)}
            kyc
          >
            {accessToken ? (
              <ComponentScroll fullHeight>
                <ModalInner>
                  <SumsubWebSdk
                    accessToken={accessToken}
                    expirationHandler={handleExpiredToken}
                    config={{ theme: 'light', lang: 'en' }}
                    options={{ adaptIframeHeight: true }}
                    onMessage={handleOnMessage}
                    onError={handleOnError}
                  />
                </ModalInner>
              </ComponentScroll>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={loaderGif} alt="&nbsp;" />
              </div>
            )}
          </Modal>

          <ComponentWrapper noPaddingBottom>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper>
            <FAQ
              items={[
                {
                  title:
                    'Why does my browser keep freezing during the KYC process?',
                  content: `Your browser seems to block the identity verification pop-up window. Please deactivate any pop-up blocker and reload the page. If you are using the Brave web browser, you must disable the brave-shield located to the right of the address bar. Don't worry, you can turn it back on right after.`,
                },
                {
                  title:
                    user_type === UserTypes.investor_entity
                      ? 'Why do I need to provide company documents?'
                      : 'Why do I need to show an ID document?',
                  content:
                    user_type === UserTypes.investor_entity
                      ? `We request corporate documents to verify the legal status of your entity and comply with Know Your Business (KYB) and Anti Money Laundering (AML) regulations. In short, check if you're legally allowed to invest.`
                      : "We use ID verification in order to comply with Know Your Customer (KYC) and Anti Money Laundering (AML) regulations. In short, check if you're legally allowed to invest.",
                },
                {
                  title: 'How will my information be used?',
                  content:
                    "Your ID information will never be sold, and never shown publicly. We just use it to make sure you're legally allowed to invest through the STOKR interface.",
                },
                {
                  title: "What if I'd like to do this later?",
                  content:
                    'You can always skip this step and complete it from the Checklist in your Investor Dashboard at a later point in time. However, you will not be able to invest before doing so and being verified.',
                },
              ]}
            />
          </ComponentWrapper>
        </Column>
      </Row>
    </>
  )
}

export default VerifyIdentity
